@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300&display=swap);
@import url('./assets/css/common.css');
@import url('./assets/css/layout.css');
@import url('./assets/css/auth.css');
@import url('./assets/css/dashboard.css');

body {
  margin: 0;
  font-family: 'Open Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #FFFFFF;
  font-size: 14px;
  color: #212529;
  margin: 0;
  padding: 0;
}

/* ::-webkit-scrollbar {
  width: 0;
  background: transparent;
} */
.gradient-button {
  color: #212529;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 20px !important;
  text-align: center;
  border-radius: 10px;
  background: #fecc4e;
  cursor: pointer;
  outline: none;
  border: none;
  margin-top: 20px;
}

.transparent-button {
  color: #212529;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 16px !important;
  text-align: center;
  border-radius: 30px;
  background: transparent;
  cursor: pointer;
  outline: none;
  border: 1px solid;
}

.filter-button {
  color: #212529;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 20px !important;
  text-align: center;
  border-radius: 10px;
  background: #fecc4e;
  cursor: pointer;
  outline: none;
  border: none;
  /* height: 35px; */
}

.clear-button {
  color: #212529;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
  padding: 4px 20px !important;
  text-align: center;
  border-radius: 10px;
  background: transparent;
  cursor: pointer;
  outline: none;
  border: 1px solid #fecc4e;
}

.scale-icon {
  max-width: 18px;
  height: auto;
}

.ant-modal-title {
  color: #212529;
}

.ant-modal-content {
  background: #FFFFFF;
  color: #212529;
}

.ant-select {
  color: #212529 !important;
  outline: none !important;
  background: #FFFFFF 0% 0% no-repeat padding-box !important;
  box-shadow: none;
}

.ant-select-selector {
  min-height: 35px !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: 1px solid #d4d4d4;
  border-radius: 0.5rem;
}

.status_active {
  font-size: 12px;
  font-weight: 700;
  color: #0E6F6B;
  background-color: #C9F7F5;
  border-color: #C9F7F5;
  border-radius: 8px;
  padding: 4px 10px 4px;
  cursor: pointer;
}

.status_inactive {
  font-size: 12px;
  font-weight: 700;
  color: #AA1C2B;
  background-color: #FFE2E5;
  border-color: #FFE2E5;
  border-radius: 8px;
  padding: 4px 10px 4px;
  cursor: pointer;
}

.status_inactive:hover {
  font-weight: 700;
  color: #AA1C2B;
  background-color: #FFE2E5;
  border-color: #FFE2E5;
  border-radius: 8px;
  cursor: pointer;
}

.edit-icon {
  max-width: 30px;
  height: auto;
  font-size: 25px;
  color: #1559a2;
}
.del-icon {
  max-width: 30px;
  height: auto;
  font-size: 20px;
  color: #dd0b0b;
  cursor: pointer;
}
.attach-icon{
margin-left: 30%;}
.faq-icon {
  width: 50px;
  font-size: 25px;
  padding-left: 15px;
  color: #1559a2;
}

input[readonly] {
  background-color: #f7f7f7;
  border: unset;
}

input[readonly]:hover,
input[readonly]:focus {
  border: unset;
  box-shadow: unset;
}

.ant-menu-submenu-selected {
  fill: #1559A2;
}

.clear-button1 {
  z-index: 99;
}

.table tbody td {
  font-weight: 500;
  max-width: fit-content;
  min-width: auto;
  font-size: 13px !important;
}

.table thead th {
  font-size: 13px !important;
}

.ant-pagination-options {
  display: none;
}

.ant-form-item-control-input-content .wrapper {
  z-index: 999 !important;
}

.cmyNjn>.wrapper {

  /* height: 36px !important; */
  border: 1px solid #d4d4d4 !important;
  border-radius: 10px !important;
  min-height: 36px !important;
}

.cmyNjn>.wrapper:active {
  box-shadow: rgb(32 33 36 / 0%) 0px 1px 6px 0px !important;
}

.cmyNjn>.wrapper:hover {
  box-shadow: rgb(32 33 36 / 0%) 0px 1px 6px 0px !important;
}

.fDbOPw {
  min-height: 36px !important;
}

div#testid_erro {
  color: red;
}

.appli_view_label {
  font-size: 14px;
  font-weight: 500;
}

.appli_view {
  margin-top: 15px;
}

.job-post-add {
  position: unset;
  display: block;
  margin: auto;
  margin-right: 2%;
}

/* .tox.tox-silver-sink.tox-tinymce-aux {
  display: none;
} */

.ex-export span.anticon.anticon-file-excel {
  font-size: 30px;
  color: #109d59;
  display: inline-block;
  cursor: pointer;
}

.position-relative {
  position: relative;
}

.ex-export {
  position: absolute;
  right: 5%;
  bottom: 5px;
}

.btn-exl {
  background: none;
  border: 0px;
}

label {
  cursor: pointer;
  /* Style as you please, it will become the visible UI component. */
}

#upload-photo {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.view-attachment {
  width: 20px;
  font-size: 25px;
  margin: 0 4px;
  padding-top: 0;
}

.bg_gray {
  background: #f7f7f7;
  height: 200px;
  border: none;
}

.doc_icon {
  width: 40px;
}

.doc_icon-w {
  width: 25px;
}

.table_first_col {
  width: 70px !important;
}

.table_action_col {
  width: 150px !important;
}

.table_status_col {
  width: 150px !important;
}

.jop_btn {
  background-color: #1559a2;
  padding: 5px 15px;
  color: #fff;
  font-size: 15px;
  cursor: pointer;
  display: flex;
  border-radius: 5px;
}

.jop_btn svg {
  width: 20px;
  margin-left: 2px;
  height: 22px;
  vertical-align: middle;
}
.lead-w{
  width: 200px !important;
  max-width: 200px !important;
}
.char_count {
  float: right;
  font-size: 13px;
  position: absolute;
  right: 0;
  bottom: 0;
}
.d-none {
  display: none;
}
.c-bl{
  color: #1890ff;
}
.frameSet{
  padding: 20px 10px;
  border: 1px solid #ddd;
  margin-bottom: 10px;
}
.ant-menu-item-selected svg, .ant-menu-submenu-selected svg{
  width: 20px;
}

.menuitem svg{
  fill: #000;
}
.ant-menu-submenu-open.ant-menu-submenu-title svg{
  fill: #1559A2;
}
.ant-menu-item svg{
  width: 20px;
}
.ant-menu-item-active:hover .ant-menu-item-icon{
fill:#fff;
}
.addbtn{
border: unset;
    position: absolute;
    right: -20px;
    top: -10px;
}
.addbtn span{
  font-size: 20px;
  line-height: 0px;
}
.addbtn span svg{
  fill: #0250a9;
}
.btl-minus svg{
  font-size: 20px;
  color: red;
  position: absolute;
  top: -18px;

}
.recom-t{
  position: absolute;
  bottom: 0;
  left: 110px;
  font-size: 13px;
  color: #0050a9;
}
.recom-tcode{
  position: absolute;
  bottom: 0;
  left: 136px;
  top: 69px;
  font-size: 13px;
  color: #212529;
}
.recom-t p{
padding-bottom: 0px;
margin-bottom: 0px;
}
.btlCate{
  width: 200px;
}
.btlName{
  width: 300px;
}
.btManagement svg{
height: 20px;
}
.btManagement .ant-menu-item-selected svg{
  fill:#fff;
}
.btManagement .ant-menu-item-selected svg g{
  fill:#fff;
}
.ant-menu-submenu-selected  .ant-menu-submenu-title svg g{
  fill:#1559A2;
}
.btl_img{
  width: 50px;
}
.dire_img{
  width: 50px;
  padding: 10px 5px;
  margin-top: -15px;
}
.btl_img_class{
  background: unset;
  border: unset;
  box-shadow: unset;
  text-align: left;
  width: 50px;
  padding-left: 0px;
}
.btl_modal .ant-modal-body{
  padding: 0px;
}
.btl_modal img{
width: 100%;
}
.btl_modal .ant-modal-close {
  right: -40px;
  top: -20px;
  color: #fbba1d;
}
.btl_img_class:active,.btl_img_class:hover,.btl_img_class:focus{
  background-color: unset;
  border: unset;
}
.ex-export2{
  position: relative;
  right: -10%;
  margin-top: 10px;
}
.ex-export2 svg{
  font-size: 30px;
  fill: green;
}
.btl-filter{
  margin-left: 27px !important;
}
.part-filter {
  display: flex;
}
.part-export{
  margin-left: 58px;
}
.list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  line-height: 34px;
}
.active_inactive{
  width: max-content;
}
.slash-line{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  font-weight: 100;
}
.ant-input[disabled] {
  color: #000 !important;
}